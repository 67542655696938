@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Rubik:wght@400;500;700&display=swap");

html,
body,
#root,
.app {
  font-family: "Montserrat", sans-serif;
}
.body {
  margin: 0;
}
.MuiInputBase-input {
  background: #fff;
  border-radius: 5px;
}

.MuiTextField-root {
  background: #fff;
  border-radius: 5px;
}
